import React from "react";
import "../assets/style/globalStyle.scss";

const Card = ({ logo, onClick }) => {
  return (
    <div class="card">
      <div class="card-content">
        <div class="media">
          <button onClick={onClick} onKeyDown={onClick} tabIndex={0}>
            <figure class="image is-48x48">
              <img src={logo} alt=""></img>
            </figure>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
