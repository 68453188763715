import React, { useState, useEffect, useContext } from "react";
import CardSponsor from "../../components/cardSponsor";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
import { getAllSponsors, getSponsorById } from "../../services/sponsorService";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

const AllSponsors = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const [sponsors, setSponsors] = useState([]);

  const getAllSponsorsToDisplay = async () => {
    const data = await getAllSponsors();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas de sponsor enregistré</h1>
        </div>
      );
    }

    setSponsors(data);
  };

  const GoToSponsor = async (id) => {
    const sponsor = await getSponsorById(id);
    dispatch({
      type: "get_sponsor",
      payload: sponsor,
    });
    navigate("/app/updateSponsorPage");
  };

  useEffect(() => {
    getAllSponsorsToDisplay();
  }, []);

  return (
    <div className="columns is-multiline">
      {sponsors.length !== 0 &&
        sponsors.sponsor.map((sponsor) => (
          <div
            key={sponsor.name}
            className="column is-2-desktop is-5-mobile is-3-tablet is-1-widescreen"
          >
            <CardSponsor
              logo={
                sponsor.logo
                  ? `https://res.cloudinary.com/ball-n-connect/image/v1/upload/sponsor_image/${sponsor._id}.jpg`
                  : null
              }
              name={sponsor.name}
              onClick={() => GoToSponsor(sponsor._id)}
            />
          </div>
        ))}
    </div>
  );
};

export default AllSponsors;
